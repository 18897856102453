<template>
    <el-row class="tac">
        <el-col :span="13">
            <el-menu :default-active="active" class="el-menu-vertical-demo" style="border:none;width:200px;text-align: center;">
                <el-menu-item :index="index.toString()" v-for="(item,index) in nav" :key="index">
                    <i :class="item.icon"></i>
                    <span slot="title" @click="linkTo(item.link)" class="title-font">{{ $lang(item, "text") }}</span>
                </el-menu-item>
            </el-menu>
        </el-col>
    </el-row>
</template>
<script>
export default {
    props: {
        active: {},
    },
    data() {
        return {
            nav: [
                {
                    icon: '',
                    textZhCn: "全部订单",
                    textEnUs: "all",
                    link: {}
                },
                {
                    icon: '',
                    textZhCn: "待付款订单",
                    textEnUs: "Pending Order",
                    link: {status: "10,80"},
                },
                {
                    icon: '',
                    textZhCn: "帮他人支付订单",
                    textEnUs: "Pay for Colleague Order",
                    link: {what: "replacePay"},
                },
                {
                    icon: '',
                    textZhCn: "未提交订单",
                    textEnUs: "Unsubmitted Order",
                    link: {status: "0"},
                },
                {
                    icon: '',
                    textZhCn: "定制品订单",
                    textEnUs: "Customized Design Order",
                    link: {module: "202,203,302,303"},
                },
                {
                    icon: '',
                    textZhCn: "待发货订单",
                    textEnUs: "Order to be shipped",
                    link: {status: "70,81"},
                },
                {
                    icon: '',
                    textZhCn: "待收货订单",
                    textEnUs: "Awaiting Buyer Confirmation",
                    link: {status: "90"},
                },
                {
                    icon: '',
                    textZhCn: "已完成订单",
                    textEnUs: "Completed Order",
                    link: {status: "100"},
                },
            ],
        }
    },

    methods: {
        linkTo(data) {
            this.$emit('changeOrderStatus', data)//传递参数
        }

    }
}
</script>

<style scoped>
.title-font {
    font-size: 16px;
    font-weight: 400;
}

.is-active {
    color: #000;

}

.is-active span {
    font-weight: 600;
}
</style>
