<template>
    <el-dialog title="微信支付" :visible.sync="visible">
        <div class="qr-box">

            <template v-if="text">
                <vue-qr :text="text" :margin="0" :size="200"></vue-qr>
                <div class="tip">微信扫一扫进行支付</div>
            </template>
            <span v-else>加载中...</span>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button @click="ok" type="primary">我已成功支付</el-button>
        </div>
    </el-dialog>
</template>

<script>
import vueQr from 'vue-qr'
import {wxPay} from "../api/order";

export default {
    name: "WxPayDialog",
    components: {vueQr},
    data() {
        return {
            visible: false,
            orderId: null,
            text: ""
        }
    },
    methods: {
        async show(orderId) {
            console.log("orderId", orderId)
            this.text = "";
            this.orderId = orderId;
            this.visible = true;
            this.text = await wxPay(orderId);
        },
        cancel() {
            this.visible = false;
        },
        ok() {
            this.visible = false;
            this.$emit("ok")
        }
    }
}
</script>

<style scoped>
.qr-box {
    text-align: center;
}
.tip{
    margin-top: 20px;
    font-size: 18px;
}
</style>