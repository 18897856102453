<template>
	<div>
		<el-dialog title="我要评论" :visible.sync="visible">
			<el-input type="textarea" :rows="2" placeholder="请输入评论内容" v-model="textarea">
			</el-input>
			
			 <div class="e-margin-top-20 e-margin-bottom-20">
				 <upload ref="upload"></upload>
			 </div>
          
			<el-rate
			  v-model="value"
			  show-text>
			</el-rate>
			
			<div class="e-flex e-flex-end e-margin-top-10">
				<el-button type="primary" @click="sumbit">提交</el-button>
			</div>
			
			
		</el-dialog>

	</div>
</template>

<script>
	import upload from './upload.vue';
	import {comment} from '../api/goods';
	export default {
		components:{
			upload
		},
		data() {
			return {
				visible: false,
				orderId: null,
				textarea: '',
				value: 5,
				module:''
			}
		},
		methods: {

			show(orderId,module,goodsId) {
				this.orderId = orderId;
				this.module=module;
				this.goodsId=goodsId;
				
				
				this.visible = true;
			},
			sumbit(){
				const description=this.textarea;
				const orderId=this.orderId;
				const module=this.module;
				const goodsId=this.goodsId;
				const stars=this.value;
				var images=this.$refs.upload.imageList.map(v => v.pic).join(',');
				
				comment(description,goodsId,images,module,orderId,stars).then(res=>{
					 this.$message("发布成功")
					 this.visible = false;
					 this.$parent.getList()
				}).catch(err=>{
					
				})
			}


		}
	}
</script>

<style scoped>

</style>
