<template>
	
		 
				     <el-pagination
				          @size-change="handleSizeChange"
				          @current-change="handleCurrentChange"
				          :current-page="Pinfo.current"
				          :page-sizes="[20, 30, 40]"
				          :page-size="Pinfo.size"
				          layout="total, sizes, prev, pager, next, jumper"
				          :total="Pinfo.total">
				        </el-pagination>
				  
	
	
</template>

<script>
	export default{
		
		data(){
			return{
				
				
			}
		},
		props:{
			Pinfo:{
				type: Object,
				
			},
			
			
		},
		methods:{
			handleSizeChange(e){
				this.$emit("pageination",{pageSize:e,currentPage:1})
				
			},
			handleCurrentChange(e){
				this.$emit("pageination",{currentPage:e,pageSize:this.Pinfo.size})
			},
		}
	}
	
	
</script>