<template>
    <el-dialog title="测试支付" :visible.sync="visible">
        <div>
            支付成功！
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button @click="ok" type="primary">我已成功支付</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {testPay} from "../api/order";

export default {
    name: "TestPayDialog",
    data() {
        return {
            visible: false,
            orderId: null,
        }
    },
    methods: {
        async show(orderId) {
            console.log("orderId", orderId)
            this.orderId = orderId;
            this.visible = true;
            testPay(orderId);
        },
        cancel() {
            this.visible = false;
        },
        ok() {
            this.visible = false;
            this.$emit("ok")
        }
    }
}
</script>

<style scoped>

</style>