<template>
	<div>
		<el-dialog :title="$t('newadd.t.输入代付用户')" :visible.sync="visible">
			<div>
				<div class="e-margin-bottom-20"> {{$t('orderCenter.orderSn')}}
					{{ orderId }}
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item :label="$t('newadd.t.用户') + 'ID'" prop="userId">
						<el-input :placeholder="$t('newadd.t.输入代付用户')" v-model="ruleForm.userId"></el-input>
					</el-form-item>
				</el-form>

			</div>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="sure('ruleForm')">确认</el-button>

			</div>
		</el-dialog>

		<test-pay-dialog ref="testPayDialog" @ok="ok" />
		<wx-pay-dialog ref="wxPayDialog" @ok="ok" />
		<pay-pal-pay-dialog ref="payPalPayDialog" @ok="ok" />
	</div>
</template>

<script>
	import WxPayDialog from "./WxPayDialog";
	import TestPayDialog from "./TestPayDialog";
	import PayPalPayDialog from "./PayPalPayDialog";
	import {
		mapMutations,
		mapState
	} from 'vuex';
	import {
		elseorder
	} from "@/api/order";

	export default {
		name: "PayDialog",
		components: {
			PayPalPayDialog,
			WxPayDialog,
			TestPayDialog
		},
		data() {
			return {
				visible: false,
				orderId: null,
				isReaplce: false,
				ruleForm: {
					mobile: '',
					userId: ''
				},
				rules: {


					userId: [{
						required: true,
						message: '请输入代付人的用户ID',
						trigger: 'change'
					}],

				}
			}
		},
		methods: {
			ChangeReaplce(e) {
				this.visible = false
			},
			show(orderId) {
				this.orderId = orderId;
				this.visible = true;
			},
			sure(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						elseorder(this.orderId, this.ruleForm.userId).then(res => {
							this.$message.success("提交成功");
							this.visible = false;
						}).catch(err => {

						})



					} else {

						return false;
					}
				});
			},
			ok() {
				this.visible = false;
				this.$emit("ok")
			},
			testPay() {
				this.$refs.testPayDialog.show(this.orderId);
			},
			wxPay() {
				this.$refs.wxPayDialog.show(this.orderId);
			},
			payPal() {
				this.$refs.payPalPayDialog.show(this.orderId);
			}
		}
	}
</script>

<style scoped>

</style>
