<template>
	<div v-loading="loading">
		<el-dialog title="订单详情" :visible.sync="visible" width="80%" >
			  <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto;height: 500px;" >
			<el-card>
				<div v-for="(item,index) in info.orderGoodsList" :key="index" style="display: flex;">
					
					<el-image :src='$req.getFileUrl(item.img)' style="width: 100px; height: 100px"></el-image>
					<div class="e-margin-left-20" style="display: flex;justify-content:space-between;flex-direction: column;width: 100%;">
						<div>{{ $lang(item, "title") }}</div>
						<div>{{$t('orderDetail.figureNumber')}}:{{item.figureNumber}}</div>
						<div class="" style="display: flex;justify-content: space-between;">
							<div>{{$transformMoney(item.uniMoney,info.currency)}}</div>
							<div>{{"X" + item.num}}</div>
						</div>
					</div>
				</div>
			</el-card>
			<el-row>
				<el-col :span="6" v-if="info.id">{{$t('orderDetail.id')}}:{{info.id}}</el-col>
				<el-col :span="6" v-if="info.moneyFirst">{{$t('orderDetail.moneyFirst')}}:{{$transformMoney(info.moneyFirst,info.currency)}}</el-col>
				<el-col :span="6" v-if="info.moneyOriginal">{{$t('orderDetail.moneyOriginal')}}:{{$transformMoney(info.moneyOriginal,info.currency)}}</el-col>
				<el-col :span="6" v-if="info.moneySecond">{{$t('orderDetail.moneySecond')}}:{{$transformMoney(info.moneySecond,info.currency)}}</el-col>
				<el-col :span="6" v-if="info.payFirstTime">{{$t('orderDetail.payFirstTime')}}:{{info.payFirstTime}}</el-col>
				<el-col :span="6" v-if="info.paySecondTime">{{$t('orderDetail.paySecondTime')}}:{{$transformMoney(info.paySecondTime,info.currency)}}</el-col>
				<el-col :span="6" v-if="info.payType">{{$t('orderDetail.payType')}}: {{ $t('pay.'+info.payType)}}</el-col>
				
				<el-col :span="6" v-if="info.purchasingCost">{{$t('orderDetail.purchasingCost')}}:{{$transformMoney(info.purchasingCost,info.currency)}}</el-col>
				<el-col :span="6" v-if="info.returnTime">{{$t('orderDetail.returnTime')}}:{{info.returnTime}}</el-col>
				<el-col :span="6" v-if="info.requireInvoice">{{$t('orderDetail.requireInvoice')}}:{{ $t('need.'+info.requireInvoice)}}</el-col>
				<el-col :span="6" v-if="info.returnTime">{{$t('returnTime')}}:{{info.returnTime}}</el-col>
				<el-col :span="6" v-if="info.country">
					{{$t('orderDetail.country')}}:{{info.address.country }}{{info.address.province }}{{info.address.city }}</el-col>
				<el-col :span="6" v-if="info.realname">{{$t('orderDetail.realname')}}:{{info.address.realname }}</el-col>
				<el-col :span="6" v-if="info.phone">{{$t('orderDetail.phone')}}:{{info.address.phone}}</el-col>
				<el-col :span="6" v-if="info.userId">{{$t('orderDetail.userId')}}ID:{{info.address.userId }}</el-col>
				<el-col :span="6" v-if="info.replacePayUserId">{{$t('orderDetail.replacePayUserId')}}:{{info.replacePayUserId}}</el-col>
				<el-col :span="6" v-if="info.status">{{$t('orderDetail.status')}}:{{ $t('orderStatus.'+info.status)}}</el-col>
				<el-col :span="6" v-if="info.userRemark">{{$t('orderDetail.userRemark')}}:{{info.userRemark}}</el-col>
				<div v-if="info.invoice">
					<el-col :span="6">
						{{$t('orderDetail.companyName')}}：{{info.invoice.companyName}}
					</el-col>
					<el-col :span="6">
						{{$t('orderDetail.createTime')}}：{{info.invoice.createTime}}
					</el-col>
					<el-col :span="6">
						{{$t('orderDetail.dutyParagraph')}}：{{info.invoice.dutyParagraph}}
					</el-col>
					<el-col :span="6">
						{{$t('orderDetail.email')}}：{{info.invoice.email }}
					</el-col>
				</div>
				<el-col :span="6" v-if="info.confirmReceiptTime">
					{{$t('orderDetail.confirmReceiptTime')}}：{{info.confirmReceiptTime }}
				</el-col>
				<el-col :span="6" v-if="info.contactId">
					{{$t('orderDetail.contactId')}}：{{info.contactId}}
				</el-col>
				<el-col :span="6" v-if="info.courierCompany">
					{{$t('orderDetail.courierCompany')}}：{{info.courierCompany }}
				</el-col>
				<el-col :span="6" v-if="info.contractEnUs">
					<a :href="info.contractZhCn">{{$t('orderDetail.contractEnUs')}}</a>
				</el-col>
				<el-col :span="6" v-if="info.contractZhCn">
					<a :href="info.contractEnUs">{{$t('orderDetail.contractZhCn')}}</a>
				</el-col>
				<el-col :span="6" v-if="info.deliveryDate">
					{{$t('orderDetail.deliveryDate')}}：{{info. deliveryDate }}
				</el-col>
				<el-col :span="6" v-if="info.discountAgain">
					{{$t('orderDetail.discountAgain')}}:{{$transformMoney(info.discountAgain,info.currency) }}
				</el-col>
				<el-col :span="6" v-if="info.discountContact">
					{{$t('orderDetail.discountContact')}}:{{$transformMoney(info. discountContact,info.currency) }}
				</el-col>
				<el-col :span="6" v-if="info.engineerId">
					{{$t('orderDetail.engineerId')}}:{{info. engineerId }}
				</el-col>
				<el-col :span="6" v-if="info.freightMoney">
					{{$t('orderDetail.freightMoney')}}:{{info. freightMoney }}
				</el-col>
				<el-col :span="6" v-if="info.drewInvoice">
					{{$t('orderDetail.drewInvoice')}}：{{info. drewInvoice }}
				</el-col>
				<el-col :span="6" >{{$t('orderDetail.insure')}}:{{info.insure?'有':'无'}}</el-col>
				<el-col :span="6" v-if="info.insureMoney">{{$t('orderDetail.insureMoney')}}:{{$transformMoney(info. insureMoney,info.currency) }}</el-col>
				<el-col :span="6" v-if="info.goodsMoney">{{$t('orderDetail.goodsMoney')}}:{{$transformMoney(info.goodsMoney,info.currency) }}</el-col>
				<el-col :span="6" v-if="info.module">{{$t('orderDetail.module')}}:{{$t('module.'+info.module)}}</el-col>
			</el-row>
			
			
	
			<group-card title="custom.page1.芯体" in-line v-if="goodsMirror.calcUserResult">
			  <!--长-->
			  <group-item label="custom.page1.长">{{ goodsMirror.calcUserResult.dLength }}mm</group-item>
			  <!--扁管数量-->
			  <group-item label="custom.page2.扁管数量">{{ goodsMirror.calcUserResult.Ntube }}</group-item>
			  <!--高-->
			  <group-item label="custom.page1.高">{{ goodsMirror.calcUserResult.dHeight }}mm</group-item>
			  <!--扁管型号-->
			  
			  <group-item label="custom.page2.扁管型号">
			  {{ `${goodsMirror.calcUserParam.dTube.w}*${goodsMirror.calcUserParam.dTube.h}*${goodsMirror.calcUserParam.dTube.n}` }}
			  </group-item>
			  <!--排数-->
			  <group-item label="custom.page1.排数">{{ goodsMirror.calcUserParam.iBank }}</group-item>
			  <!--翅片型号-->
			  <group-item label="custom.page2.翅片型号">{{ `${goodsMirror.calcUserParam.dFin.thick}*${goodsMirror.calcUserParam.dFin.w}*${goodsMirror.calcUserParam.dFin.h} FPI${goodsMirror.calcUserParam.dFin.fpi}` }}</group-item>
			  <!--流程-->
			  <group-item label="custom.page2.流程">{{ goodsMirror.calcUserResult.NPass }}</group-item>
			</group-card>
			
			
			
			
			
			<group-card title="custom.page3.部件" in-line v-if="goodsMirror.goodsParam">
			  <template v-if="goodsMirror.goodsParam.page3.type == 0">
			    <!--风扇直径-->
			    <group-item label="custom.page3.风扇直径" v-if="goodsMirror.goodsParam.page3.fanDiameter ">{{ goodsMirror.goodsParam.page3.fanDiameter }}mm</group-item>
			    <template v-if="goodsMirror.goodsParam.page3.installationFan">
			      <!--风机安装孔直径-->
			      <group-item label="custom.page3.风机安装孔直径" v-if=" goodsMirror.goodsParam.page3.apertureDiameter">{{  goodsMirror.goodsParam.page3.apertureDiameter }}mm</group-item>
			      <!--风机数量-->
			      <group-item label="custom.page3.风机数量" v-if="goodsMirror.goodsParam.page3.fanNum">{{  goodsMirror.goodsParam.page3.fanNum }}</group-item>
			    </template>
			    <!--风罩位置-->
			    <group-item label="custom.page3.风罩位置">{{ getFanPosition() }}</group-item>
			    <!--拉铆螺母规格-->
			    <group-item label="custom.page3.拉铆螺母规格">{{ $lang( goodsMirror.goodsParam.page3.nut, 'title') }}</group-item>
			    <!--是否翻边-->
			    <group-item label="custom.page3.是否翻边">{{ getIsFlanging() }}</group-item>
			  </template>
			  
			  
			  
			  <template v-if="goodsMirror.goodsParam.page3.type == 1">
			    <!--支架位置-->
			    <group-item label="custom.page3.支架位置">{{ getBracketLocation() }}</group-item>
			    <!--支架规格-->
			    <group-item label="custom.page6.支架规格">{{ getBrackeInfo() }}</group-item>
			  </template>
			  
			  
			  <!--涂层-->
			  <group-item label="custom.page5.涂层">{{ $t("custom.page5.涂层" + goodsMirror.goodsParam.coating) }}</group-item>
			  <!--是否充氮-->
			  <group-item label="custom.page5.是否充氮">
			    {{ $t("common.boolean." + goodsMirror.goodsParam.isNitrogenFilling.toString()) }}
			  </group-item>
			  
			</group-card>
			
			
			
			<group-card title="custom.page2.性能" in-line v-if="goodsMirror.calcUserParam">
			  <!--环境温度-->
			  <group-item label="custom.page0.环境温度">{{ goodsMirror.calcUserParam.dAirInT }}℃</group-item>
			  <!--换热量-->
			  <group-item label="custom.page2.换热量">{{ goodsMirror.calcUserResult.dQ }}W</group-item>
			  <!--排气温度-->
			  <group-item label="custom.page0.排气温度">{{ goodsMirror.calcUserParam.dRefInT }}℃</group-item>
			  <!--冷媒-->
			  <group-item label="custom.page0.冷媒">{{ goodsMirror.calcUserParam.sRefName }}kPa</group-item>
			  <!--排气压力-->
			  <group-item label="custom.page0.排气压力">{{ goodsMirror.calcUserParam.dRefInP }}MPa</group-item>
			  <!--出风温度-->
			  <group-item label="custom.page2.出风温度">{{ goodsMirror.calcUserResult.dTout_a }}℃</group-item>
			  <!--过冷度-->
			  <group-item label="custom.page0.过冷度">{{ goodsMirror.calcUserParam.dRefOutSC }}℃</group-item>
			  <!--风速-->
			  <group-item label="custom.page0.风速">{{ goodsMirror.calcUserResult.dVel_a }}m/s</group-item>
			  <!--流量-->
			  <group-item label="custom.page2.流量">{{ goodsMirror.calcUserResult.dMF_r }}m^3/h</group-item>
			  <!--风量-->
			  <group-item label="custom.page0.风量">{{ goodsMirror.calcUserResult.dVF_a }}m^3/h</group-item>
			  <!--冷媒降压-->
			  <group-item label="custom.page2.冷媒降压">{{ goodsMirror.calcUserResult.dDP_r }}kPa</group-item>
			  <!--风压-->
			  <group-item label="custom.page0.风压">{{ goodsMirror.calcUserResult.dDP_a }}Pa</group-item>
			  <!--冷媒充注量-->
			  <group-item label="custom.page2.冷媒充注量">{{ goodsMirror.calcUserResult.dCharge }}g</group-item>
			</group-card>
			
			
			

  </ul>

		</el-dialog>
	</div>
</template>

<script>
	import GroupCard from "@/views/Custom/components/GroupCard";
	import GroupItem from "@/views/Custom/components/GroupItem";
	
	import {
		mapMutations,
		mapState
	} from 'vuex';
	import {
		orderDeatail
	} from "@/api/order";

	export default {
components: {GroupItem, GroupCard},

		data() {
			return {
				visible: false,
				orderId: null,
				info: {

				},
				loading: false,
                goodsMirror:''
			}
		},
		methods: {
		  	getIsFlanging() {
		      let b = this.goodsMirror.goodsParam.page3.isFlanging;
		      let flag = "common.boolean." + b.toString()
		      return this.$t(flag)
		    },
			getFanPosition() {
			  let position = this.goodsMirror.goodsParam.page3.fanPosition;
			  let flag = "custom.hoodPosition." + position
			  return this.$t(flag)
			},
			getBracketLocation() {
			  let n = this.goodsMirror.goodsParam.page3.bracketLocation
			  let flag = "custom.bracketPosition." + n
			  return this.$t(flag)
			},
			getBrackeInfo() {
				if( !this.goodsMirror.goodsParam.page3.bracket){
					
					return
				}
			  let model = this.goodsMirror.goodsParam.page3.bracket.otherObj;
			  return `${model.length.value}*${model.width.value}*${model.height.value}`;
			},
			load() {
				//2021-12-20 TODO
			},
			show(orderId) {

				orderDeatail(orderId).then(res => {
					if (res.invoice) {
						res.invoice = JSON.parse(res.invoice)
					}
					if (res.address) {
						res.address = JSON.parse(res.address)
					}
					if (res.orderGoodsList[0].goodsMirror) {
						this.goodsMirror = JSON.parse(res.orderGoodsList[0].goodsMirror);
					console.log(this.goodsMirror,"goodsMirror")
					}
					
					this.info = res;
					
					this.visible = true;
					this.orderId = orderId;
					this.loading = false
				}).catch(err => {

				})


			},

		}
	}
</script>

<style scoped>
	.el-col {
		margin-top: 20px;
	}
</style>
