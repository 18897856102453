<template>
    <el-dialog title="PayPal" :visible.sync="visible">
        <div>
            {{ text }}
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button @click="ok" type="primary" :disabled="loading">我已成功支付</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {payPalPay} from "../api/order";

export default {
    name: "PayPalPayDialog",
    components: {},
    data() {
        return {
            text: "",
            visible: false,
            orderId: null,
            loading: true,
        }
    },
    methods: {
        async show(orderId) {
            console.log("orderId", orderId)
            this.orderId = orderId;
            this.visible = true;
            this.text = "加载中...";
            this.loading = true;
            let url = await payPalPay(orderId);
            this.text = "请在新窗口完成支付";
            this.loading = false;
            window.open(url, "_blank");
        },
        cancel() {
            this.visible = false;
        },
        ok() {
            this.visible = false;
            this.$emit("ok")
        }
    }
}
</script>

<style scoped>

</style>