import fetch from "@/api/fetch";

const u = "client/goods/standard/";

const g = "client/goods/proxy/";

const c = "client/goods/classify/";

const n = "client/goods/standard/";

const ct = "client/goods/";

export function goodsList(module, orderByField, orderBySort, moneyMin, moneyMax, keyword, current, size, classifyId,
	type, detailType,workingCondition) {
	if (type == 1) {
		var url = `${g}list`
	} else if (type == 2) {

		var url = `${u}list`
	}

	return fetch({
		url: url,
		method: "GET",
		params: {
			module: detailType,
			orderByField,
			orderBySort,
			moneyMin,
			moneyMax,
			keyword,
			current,
			size: size,
			classifyId,
			workingCondition
		}
	})

}


export function getclassify(parent) {
	return fetch({
		url: `${c}list`,
		method: "GET",
		params: {
			parent
		}
	})
}

export function getGoodsDetail(id) {
	return fetch({
		url: `${g}get`,
		method: "GET",
		params: {
			id
		}
	})
}


export function getGoodsNormalDetail(id) {

	return fetch({
		url: `${u}get`,
		method: "GET",
		params: {
			id
		}

	})

}


// 评价
export function comment(description, goodsId, images, module, orderId, stars) {

	return fetch({
		url: `${ct}comment/add`,
		method: "POST",
		data: {
			description,
			goodsId,
			images,
			module,
			orderId,
			stars
		}

	})

}


// 平行流-标准品-性能计算
export function checkout(data,type) {
	var url=type==201?`/client/goods/calc/calcMpfStandard`:`/client/goods/calc/calcMstStandard`
    return fetch({
			url: url,
			method: "POST",
			data
		})
}

